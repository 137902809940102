var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col",class:[("font-" + (_vm.pageFont || 'inter'))],style:(Object.assign({}, {'--darkColor': _vm.colors.dark,
    '--shade1Color': _vm.colors.shade1,
    '--shade2Color': _vm.colors.shade2,
    '--mainColor': _vm.colors.main,
    '--accentColor': _vm.colors.accent,
    '--tint1Color': _vm.colors.tint1,
    '--tint1Hue': _vm.hsValues.tint1.h,
    '--tint1Saturation': _vm.hsValues.tint1.s,
    '--tint2Hue': _vm.hsValues.tint2.h,
    '--tint2Saturation': _vm.hsValues.tint2.s,
    '--tint2Color': _vm.colors.tint2,
    '--lightGrayColor': _vm.colors.lightGray,
    '--lightColor': _vm.colors.light,
    backgroundImage: _vm.bgGradient},
    _vm.cssColorVars))},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }