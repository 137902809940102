<template>
  <div
    class="flex flex-col"
    :class="[`font-${pageFont || 'inter'}`]"
    :style="{
      '--darkColor': colors.dark,
      '--shade1Color': colors.shade1,
      '--shade2Color': colors.shade2,
      '--mainColor': colors.main,
      '--accentColor': colors.accent,
      '--tint1Color': colors.tint1,
      '--tint1Hue': hsValues.tint1.h,
      '--tint1Saturation': hsValues.tint1.s,
      '--tint2Hue': hsValues.tint2.h,
      '--tint2Saturation': hsValues.tint2.s,
      '--tint2Color': colors.tint2,
      '--lightGrayColor': colors.lightGray,
      '--lightColor': colors.light,
      backgroundImage: bgGradient,
      ...cssColorVars,
    }"
  >
    <slot />
  </div>
</template>

<script>
import { get } from 'vuex-pathify'
import colors from '@/mixins/colors'

export default {
  mixins: [colors],

  computed: {
    pageFont: get('page/obj@data.settings.font'),
    pageBgColor: get('page/bgColor'),
    pageItems: get('page/items'),

    item() {
      return {
        ...{
          props: {
            bgColor: this.pageBgColor,
          },
        },
      }
    },
  },
}
</script>

<style>
.page--single-section .page__sections {
  @apply flex-grow flex flex-col items-center justify-center;
}

[data-bg-color-key='dark'] {
  background-color: var(--darkColor);
}

[data-bg-color-key='shade1'] {
  background-color: var(--shade1Color);
}

[data-bg-color-key='shade2'] {
  background-color: var(--shade2Color);
}

[data-bg-color-key='main'] {
  background-color: var(--mainColor);
}

[data-bg-color-key='accent'] {
  background-color: var(--accentColor);
}

[data-bg-color-key='tint1'] {
  background-color: var(--tint1Color);
}

[data-bg-color-key='tint2'] {
  background-color: var(--tint2Color);
}

[data-bg-color-key='lightGray'] {
  background-color: var(--lightGrayColor);
}

[data-bg-color-key='light'] {
  background-color: var(--lightColor);
}
</style>
